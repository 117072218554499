.section1 {
  background-image: url("../../assets/Background.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.sectionmobile {
  background-image: url("../../assets/Background_mobile.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.titlecontainer2 {
  background-image: url("../../assets/Yellow_Vector_line.svg");
  background-repeat: no-repeat;
  min-height: auto;
  background-size: contain;
  background-position: center;
}

.linktext {
  text-decoration: none;
  color: #7640e7;
}
.linktext2 {
  text-decoration: none;
  color: white;
}
.sectiontabs {
  background-image: url("../../assets/BG_tabs.png");
  background-repeat: no-repeat;
  max-width: 100%;
  min-height: 982px;
  background-size: cover;
}
.cards {
  background-image: url("../../assets/BG_section2_small.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.imagewidth {
  width: 175px;
}
