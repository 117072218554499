.bg {
  background-image: url("../../assets/BG_school.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.bgmobile {
  background-image: url("../../assets/BG_school_mobile.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.bgpost {
  background-image: url("../../assets/Background.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.bgpostmobile {
  background-image: url("../../assets/Background_mobile.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.titlecontainer2 {
  background-image: url("../../assets/Yellow_Vector_line.svg");
  background-repeat: no-repeat;
  min-height: auto;
  background-size: contain;
  background-position: center;
}