.bgfooter {
  background-image: url("../../assets/BG_section4.png");
  background-repeat: no-repeat;
  max-width: 100%;
  max-height: 260px;
  background-size: cover;
}
.logo {
  max-height: 60%;
  max-width: 60%;
}
.logomobile {
  max-width: 40%;
}
.tabs {
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  text-decoration: none;
  text-transform: none;
}
