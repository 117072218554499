body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "CircularStd-bold";
  src: local("CircularStd"),
    url("../src/fonts/CircularStd/CircularStd-Bold.otf") format("opentype");
  font-weight: bold;
}
@font-face {
  font-family: "CircularStd-book";
  src: local("CircularStd"),
    url("../src/fonts/CircularStd/CircularStd-Book.otf") format("opentype");
  font-weight: book;
}
@font-face {
  font-family: "CircularStd-black";
  src: local("CircularStd"),
    url("../src/fonts/CircularStd/CircularStd-Black.otf") format("opentype");
  font-weight: black;
}

@font-face {
  font-family: "PlusJakartaSans-Bold";
  src: local("PlusJakartaSans"),
    url("../src/fonts/PlusJakartaSans/PlusJakartaSans-Bold.ttf")
      format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "PlusJakartaSans-Medium";
  src: local("PlusJakartaSans"),
    url("../src/fonts/PlusJakartaSans/PlusJakartaSans-Medium.ttf")
      format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "PlusJakartaSans-Regular";
  src: local("PlusJakartaSans"),
    url("../src/fonts/PlusJakartaSans/PlusJakartaSans-Regular.ttf")
      format("truetype");
  font-weight: 450;
}

@font-face {
  font-family: "ChunkFive-Regular";
  src: local("ChunkFive"),
    url("../src/fonts/ChunkFive/ChunkFive-Regular.otf") format("opentype");
  font-weight: 400;
}
