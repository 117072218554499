.LoadingSpinner_centered__3jNQw {
  margin: 4rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LoadingSpinner_spinner__1kjc9 {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.LoadingSpinner_spinner__1kjc9:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ffef00;
  border-color: #ffef00 transparent #ffef00 transparent;
  -webkit-animation: LoadingSpinner_spinner__1kjc9 1.2s linear infinite;
          animation: LoadingSpinner_spinner__1kjc9 1.2s linear infinite;
}
@-webkit-keyframes LoadingSpinner_spinner__1kjc9 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes LoadingSpinner_spinner__1kjc9 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*.header {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: left;
  padding: 30px 150px;
  overflow: visible;
}
*/

/* .base {
  background-image: url("../../assets/BG_NAV.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70px;
} */

.Header_centered__2lU9_ {
  margin: 3rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header_logo__PM4Ep {
  width: 132px;
  height: 25px;
  padding-bottom: 5px;
}

.Header_logomobile__1NTSG {
  width: 120.64px;
  height: 22px;
}

.Header_title__2dpKs {
  white-space: pre;
  overflow: visible;
  font-family: "CircularStd-book", serif;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 26.4px;
}

/*
.modalimage {
  background-image: url("../../assets/ForStudents.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 3000px;
}
*/

.Header_tabs__31KZ- {
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 700;
  font-style: normal;
}
.Header_tab__2KxJp {
  color: white;
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 700;
  font-style: normal;
}
.Header_tab__2KxJp :hover {
  color: #ffef00;
  text-decoration: none;
  text-transform: capitalize;
}

.Header_tab__2KxJp :active {
  color: #ffef00;
  text-decoration: none;
  text-transform: capitalize;
}

.Header_active__3Nqmx {
  color: #ffef00;
  text-decoration: none;
}

.Header_menuItem__3tRTW {
  color: white;
  font-size: 14px;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 700;
  font-style: normal;
}

.Header_submenus__38tNg {
  color: #000000 !important;
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 400;
  font-style: normal;
}
.Header_submenu__14xSH {
  color: #000000 !important;
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 400;
  font-style: normal;
}
.Header_submenu__14xSH :hover {
  color: #000000 !important;
  text-decoration: none;
  text-transform: capitalize;
}

.Header_submenu__14xSH :active {
  color: #000000 !important;
  text-decoration: none;
  text-transform: capitalize;
}

.LandingPageContent_section1__38s93 {
  background-image: url(/static/media/Background.d763a15e.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.LandingPageContent_sectionmobile__229mL {
  background-image: url(/static/media/Background_mobile.858316db.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.LandingPageContent_titlecontainer2__3t7fe {
  background-image: url(/static/media/Yellow_Vector_line.d54c39df.svg);
  background-repeat: no-repeat;
  min-height: auto;
  background-size: contain;
  background-position: center;
}

.LandingPageContent_linktext__1Dyzw {
  text-decoration: none;
  color: #7640e7;
}
.LandingPageContent_linktext2__1MlK9 {
  text-decoration: none;
  color: white;
}
.LandingPageContent_sectiontabs__3fj8G {
  background-image: url(/static/media/BG_tabs.4fd5b2ef.png);
  background-repeat: no-repeat;
  max-width: 100%;
  min-height: 982px;
  background-size: cover;
}
.LandingPageContent_cards__2cxfg {
  background-image: url(/static/media/BG_section2_small.5af3cd90.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.LandingPageContent_imagewidth__2YM7d {
  width: 175px;
}

.Footer_bgfooter__1cIXK {
  background-image: url(/static/media/BG_section4.009047cb.png);
  background-repeat: no-repeat;
  max-width: 100%;
  max-height: 260px;
  background-size: cover;
}
.Footer_logo__2gmLl {
  max-height: 60%;
  max-width: 60%;
}
.Footer_logomobile__2XQ1G {
  max-width: 40%;
}
.Footer_tabs__1Bo8P {
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  text-decoration: none;
  text-transform: none;
}

.BlogContent_bg__1Nqqj {
  background-image: url(/static/media/BG_school.ff455b92.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.BlogContent_bgmobile__35V9r {
  background-image: url(/static/media/BG_school_mobile.e14edda4.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.BlogContent_bgpost__1Fjgd {
  background-image: url(/static/media/Background.d763a15e.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.BlogContent_bgpostmobile__2Q_sJ {
  background-image: url(/static/media/Background_mobile.858316db.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.BlogContent_titlecontainer2__3udoH {
  background-image: url(/static/media/Yellow_Vector_line.d54c39df.svg);
  background-repeat: no-repeat;
  min-height: auto;
  background-size: contain;
  background-position: center;
}
.PlansContent_section1__1s8ws {
  background-image: url(/static/media/BG_school.ff455b92.png);
  background-repeat: no-repeat;
  max-width: 100%;
  background-size: cover;
}

.PlansContent_section1mobile__3ync8 {
  background-image: url(/static/media/BG_school_mobile.e14edda4.png);
  background-repeat: no-repeat;
  max-width: 100%;
  background-size: cover;
}

.PlansContent_titlecontainer2___ommD {
  background-image: url(/static/media/Yellow_Vector_line.d54c39df.svg);
  background-repeat: no-repeat;
  min-height: auto;
  background-size: contain;
  background-position: center;
}

.PlansContent_plansBanner__28Gle {
  background-image: url(/static/media/plans.26f34a65.png);
  background-repeat: no-repeat;
  max-width: 100%;
  background-size: cover;
}

.PlansContent_planCard1__1W3qY {
  border-radius: 25px !important;
  border: 8px solid #7640e7;
  background-color: white;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 40px 35px;
}

.PlansContent_line1__m9tiK {
  border: 5px solid #7640e7;
  width: 100%;
}

.PlansContent_line2__3jtoG {
  border: 5px solid white;
  width: 100%;
}

.PlansContent_planList__3fuFy {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 60px;
  width: 100%;
}

.PlansContent_planCard2__14xsn {
  border: 8px solid #9a71f1;
  border-radius: 25px !important;
  background-color: #9a71f1 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 40px 35px;
}

.PlansContent_planCard3__3-qG1 {
  border: 8px solid #7640e7;
  border-radius: 25px !important;
  background-color: #7640e7 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 40px 35px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "CircularStd-bold";
  src: local("CircularStd"),
    url(/static/media/CircularStd-Bold.6baed2bf.otf) format("opentype");
  font-weight: bold;
}
@font-face {
  font-family: "CircularStd-book";
  src: local("CircularStd"),
    url(/static/media/CircularStd-Book.6365c40a.otf) format("opentype");
  font-weight: book;
}
@font-face {
  font-family: "CircularStd-black";
  src: local("CircularStd"),
    url(/static/media/CircularStd-Black.7f42d848.otf) format("opentype");
  font-weight: black;
}

@font-face {
  font-family: "PlusJakartaSans-Bold";
  src: local("PlusJakartaSans"),
    url(/static/media/PlusJakartaSans-Bold.202ed785.ttf)
      format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "PlusJakartaSans-Medium";
  src: local("PlusJakartaSans"),
    url(/static/media/PlusJakartaSans-Medium.86c690cf.ttf)
      format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "PlusJakartaSans-Regular";
  src: local("PlusJakartaSans"),
    url(/static/media/PlusJakartaSans-Regular.1c536074.ttf)
      format("truetype");
  font-weight: 450;
}

@font-face {
  font-family: "ChunkFive-Regular";
  src: local("ChunkFive"),
    url(/static/media/ChunkFive-Regular.e353db8d.otf) format("opentype");
  font-weight: 400;
}

