.section1 {
  background-image: url("../../assets/BG_school.png");
  background-repeat: no-repeat;
  max-width: 100%;
  background-size: cover;
}

.section1mobile {
  background-image: url("../../assets/BG_school_mobile.png");
  background-repeat: no-repeat;
  max-width: 100%;
  background-size: cover;
}

.titlecontainer2 {
  background-image: url("../../assets/Yellow_Vector_line.svg");
  background-repeat: no-repeat;
  min-height: auto;
  background-size: contain;
  background-position: center;
}

.plansBanner {
  background-image: url("../../assets/plans.png");
  background-repeat: no-repeat;
  max-width: 100%;
  background-size: cover;
}

.planCard1 {
  border-radius: 25px !important;
  border: 8px solid #7640e7;
  background-color: white;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 40px 35px;
}

.line1 {
  border: 5px solid #7640e7;
  width: 100%;
}

.line2 {
  border: 5px solid white;
  width: 100%;
}

.planList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 60px;
  width: 100%;
}

.planCard2 {
  border: 8px solid #9a71f1;
  border-radius: 25px !important;
  background-color: #9a71f1 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 40px 35px;
}

.planCard3 {
  border: 8px solid #7640e7;
  border-radius: 25px !important;
  background-color: #7640e7 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 40px 35px;
}
