/*.header {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: left;
  padding: 30px 150px;
  overflow: visible;
}
*/

/* .base {
  background-image: url("../../assets/BG_NAV.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70px;
} */

.centered {
  margin: 3rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 132px;
  height: 25px;
  padding-bottom: 5px;
}

.logomobile {
  width: 120.64px;
  height: 22px;
}

.title {
  white-space: pre;
  overflow: visible;
  font-family: "CircularStd-book", serif;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 26.4px;
}

/*
.modalimage {
  background-image: url("../../assets/ForStudents.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 3000px;
}
*/

.tabs {
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 700;
  font-style: normal;
}
.tab {
  color: white;
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 700;
  font-style: normal;
}
.tab :hover {
  color: #ffef00;
  text-decoration: none;
  text-transform: capitalize;
}

.tab :active {
  color: #ffef00;
  text-decoration: none;
  text-transform: capitalize;
}

.active {
  color: #ffef00;
  text-decoration: none;
}

.menuItem {
  color: white;
  font-size: 14px;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 700;
  font-style: normal;
}

.submenus {
  color: #000000 !important;
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 400;
  font-style: normal;
}
.submenu {
  color: #000000 !important;
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 400;
  font-style: normal;
}
.submenu :hover {
  color: #000000 !important;
  text-decoration: none;
  text-transform: capitalize;
}

.submenu :active {
  color: #000000 !important;
  text-decoration: none;
  text-transform: capitalize;
}
